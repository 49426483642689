.whole-component {
  text-align: center;
}

.App-logo {
 width:150px;
 height:60px;
 margin-bottom:1rem !important;
}

.outer-component {
  background-color: #F2F2F2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 2rem; 
}

.form-outer-cont {
  background-color: white;
  width: 35rem; 
  border-radius: 1.25rem;
  height: auto;
  margin-bottom: 2rem;
  padding: 2rem;
  box-sizing: border-box; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thanks-quote {
  color: #1A1A1A;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-align: start;
 
}

.thanks-quote span {
  color: #5A1798;
 
} 

.copy-cont {
  color: #666666;
  font-size: 0.875rem;
  margin-bottom:0.8rem;
}
.star-rating-container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.star-rating {
  display: flex;
  justify-content: center;
 
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
  
}

.form-group label {
  display: flex;
  text-align: start;
  margin-bottom: 0.5rem;
  color: #5A189A;
  font-size: 1rem;
  font-weight: 600;
}

.form-group input {
  width: 100%;
  padding:0.5rem;
  font-size: 1rem;
  border: 2px solid #EBEBEB;
  border-radius: 0.625rem;
  background-color: #FCFCFC;
  box-sizing: border-box;
 color:#171717; 
}

.form-group textarea[placeholder="Share your feedback"]{
  width:100%;
  height: 100px;
  margin-bottom:1.5rem;
  font-size: 1rem;
  text-align: left; 
  box-sizing: border-box; 
  resize: none;
  background-color: #FCFCFC;
  border-radius:0.625rem;
  padding:0.7rem;
  font-style: normal;
  font-weight: 500; 
  color:#171717;
  border: 2px solid #EBEBEB;
  font-family: 'Lato', sans-serif;
}
.form-group textarea::placeholder {
  color: #999999; 
  font-size: 1rem; 
  font-style:normal !important;
  font-weight:normal;
  opacity: 1;
  font-family: Lato, sans-serif; 
}
input::placeholder,
textarea::placeholder {
  color: #999999; 
  font-size: 1rem; 
  font-style:normal !important;
  font-weight:normal;
  opacity: 1;
}

button {
  width: 100%;
  height:3.25rem;
  background-color: #5A189A;
  border-radius:0.625rem;
  color: white;
  border: none;
  font-size:1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: black;
}
.success-message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:1.25rem;
  font-size:1.125rem;
  color: green;
  flex-direction: column;
  font-weight: 600;
}

.success-icon {
  font-size: 3.75rem !important;
  margin-bottom:0.625rem;
}
.tech-container {
  display: flex;
  flex-direction:row;
}
.powered-by {
  font-weight: 500;
  color:#666666;
  font-size:14px;
  margin-right:0.8rem;
  display: flex;
  justify-content: center;
  align-self:center;

}
.thofa{
  display: flex;
  flex-direction: row;
  gap:0.2rem;
}
.thofa-text {
  color: #F50052;
  font-size:14px;
  font-weight: 600;
}

.technologies-text {
  color: #07A048;
  font-size:14px;
  font-weight: 600;
}
@media (max-width: 576px) {
  .form-outer-cont {
    width: 90%;
    padding: 1rem;
    margin: 2rem 0;
  }
  
  button {
    height: 2.75rem;
  }
  
  .thanks-quote, .copy-cont, .powered-by {
    font-size: 0.875rem;
  }
  
  .form-group input, .form-group textarea {
    font-size: 0.875rem;
  }
}


@media (min-width: 577px) and (max-width: 768px) {
  .form-outer-cont {
    width: 75%;
    padding: 1.5rem;
    margin: 3rem 0;
  }
  
  button {
    height: 3rem;
  }
  
  .thanks-quote, .copy-cont, .powered-by {
    font-size: 1rem;
  }
  
  .form-group input, .form-group textarea {
    font-size: 1rem;
  }
}


@media (min-width: 769px) and (max-width: 1200px) {
  .form-outer-cont {
    width: 40rem;
    padding: 2rem;
    margin: 4rem 0;
  }
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.6em;
  margin-top: 5px;
}
